<template>
  <div class="w-full">
    <variant-editor-row
      v-for="(item, index) in localForm"
      :ref="`variant-editor-form-${index}`"
      :key="index"
      class="w-full"
      :model="item"
      :used-attributes="usedAttributes"
      @update:modelValue="handleUpdate(index, $event)"
      @delete="handleDelete(index)"
    />
    <!-- Add attribute -->
    <el-button
      text
      class="focus:outline-none focus:shadow-outline"
      icon="Plus"
      @click="addNewAttribute"
    >
      {{ $t('pages.products.edit.form.sections.variants.attribute.add') }}
    </el-button>
  </div>
</template>

<script>
import VariantEditorRow from './variant-editor-row'
import * as helpers from './helpers'

export default {
  components: { VariantEditorRow },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      localForm: []
    }
  },
  computed: {
    usedAttributes() {
      return this.localForm.map(({ key }) => key)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        const incoming = helpers.getItemsAsArray(this.modelValue)
        this.localForm = incoming.length
          ? incoming
          : [{ key: null, values: [] }]
      }
    }
  },
  methods: {
    getItemsAsObject() {
      return helpers.getItemsAsObject(this.localForm)
    },
    handleUpdate(index, newItem) {
      this.localForm[index] = newItem
    },
    handleDelete(index) {
      this.localForm.splice(index, 1)
    },
    addNewAttribute() {
      this.localForm.push({ key: null, values: [] })
    },

    async validate() {
      // Validate all variants rows
      let valid = true
      for (const key in this.$refs) {
        if (
          key.includes('variant-editor-form-') &&
          this.$refs[key].length > 0
        ) {
          if (!(await this.$refs[key][0].validate())) valid = false
        }
      }
      return valid
    }
  }
}
</script>
