<template>
  <th-page-wrapper
    color="var(--secondary-color)"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <page-form ref="pageForm" />
  </th-page-wrapper>
</template>

<script>
import PageForm from './components/form'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.product_templates.edit.title')
    }
  },
  components: {
    PageForm
  },
  computed: {
    isNew() {
      const id = this.$route.params?.id
      return !(id && id !== 'new')
    },
    actions() {
      return {
        permissionPrefix: 'products:product_templates',
        returnedPath: { name: 'products-product-templates-all' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    async handleSubmit() {
      await this.$refs.pageForm.submitForm()
    },
    async handleDelete() {
      await this.$refs.pageForm.delete()
    }
  }
}
</script>
