<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="flex flex-row items-end w-full"
    @submit.prevent
  >
    <div class="flex flex-row w-90">
      <!-- Attribute name -->
      <el-form-item
        class="w-40 mr-2 mb-5"
        prop="key"
        :label="$t('pages.product_templates.edit.variants.attribute.label')"
      >
        <el-input
          id="key"
          v-model="form.key"
          :placeholder="
            $t('pages.product_templates.edit.variants.attribute.placeholder')
          "
          @input="handleUpdate({ key: capitalizeFirstLetter($event) })"
        />
      </el-form-item>
      <!-- Attribute new value -->
      <el-form-item
        class="w-40 mr-2 mb-5"
        :label="$t('pages.product_templates.edit.variants.value.label')"
      >
        <el-input
          v-model="newValue"
          :disabled="!form.key"
          :placeholder="
            $t('pages.product_templates.edit.variants.value.placeholder')
          "
        >
          <template #append>
            <el-button
              data-testid="attribute-values-button"
              class="px-3"
              icon="Right"
              native-type="submit"
              @click="addAttribueValue"
            />
          </template>
        </el-input>
      </el-form-item>
    </div>
    <el-form-item props="values" class="flex-grow mb-5 tag-input">
      <!-- Values -->
      <th-tags-single-line
        id="values"
        :model-value="form.values"
        :delete-tooltip="
          $t('pages.products.edit.form.sections.variants.attribute.delete')
        "
        :modal-header="
          $t(
            'pages.products.edit.form.sections.variants.attribute.edit_attribute',
            { attribute: form.key ? `'${form.key}'` : '' }
          )
        "
        editable
        @update:modelValue="handleUpdate({ values: $event })"
        @deleteAll="$emit('delete')"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import { capitalizeFirstLetter } from '@/utils/strings'
import ThTagsSingleLine from '@components/tags/tags-single-line'

export default {
  components: { ThTagsSingleLine },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    usedAttributes: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue', 'delete'],
  data() {
    return {
      capitalizeFirstLetter,
      newValue: null
    }
  },
  computed: {
    form: {
      get() {
        return this.model
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    rules() {
      return {
        key: [
          {
            required: this.form.values.length > 0,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          },
          {
            validator: this.attributeNameValidator,
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    attributeNameValidator(_, value, callback) {
      // Check if attribute name is already used (case insensitive and white-space removed)
      const itemsWithSameName = this.usedAttributes.filter(
        (key) => key?.trim().toLowerCase() === value?.trim().toLowerCase()
      )
      if (itemsWithSameName.length > 1) {
        callback(
          new Error(
            this.$t(
              'pages.products.edit.form.sections.variants.attribute.already_exists'
            )
          )
        )
      } else {
        callback()
      }
    },
    handleUpdate(item) {
      this.form = { ...this.form, ...item }
    },
    addAttribueValue() {
      if (!this.newValue) return
      const uniqValues = new Set([...this.form.values, this.newValue])
      this.form.values = [...uniqValues]
      this.newValue = null
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
