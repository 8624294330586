<template>
  <th-wrapper
    v-loading="loading"
    class="rounded shadow-md m-8"
    :title="$t('common.titles.general_info.title')"
    :subtitle="
      $t(
        'pages.product_templates.edit.form.properties.option_template.description'
      )
    "
  >
    <el-form ref="form" :model="current" :rules="rules">
      <el-row :gutter="20" class="flex">
        <!-- name -->
        <el-col :span="12">
          <el-form-item
            :label="
              $t('pages.product_templates.edit.form.properties.name.label')
            "
            prop="name"
          >
            <el-input id="name" v-model="current.name" />
          </el-form-item>
        </el-col>
        <!-- active -->
        <el-col :span="12" class="flex items-center">
          <el-form-item prop="active" class="mb-0">
            <el-checkbox
              id="active"
              v-model="current.active"
              :aria-label="
                $t('pages.product_templates.edit.form.properties.active.label')
              "
            >
              {{
                $t('pages.product_templates.edit.form.properties.active.label')
              }}
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <variant-editor ref="variantEditor" v-model="current.option_template" />
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import VariantEditor from './variant-editor'
import variantTemplateModel from '@/models/variant-template-model'

function generateDefault() {
  return {
    name: null,
    active: true,
    option_template: {}
  }
}

export default {
  components: {
    VariantEditor
  },
  setup() {
    return variantTemplateModel.setup(generateDefault())
  },
  data() {
    return {
      valid: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.product_templates.edit.form.rules.name.required'
            ),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    })
  },
  async mounted() {
    this.id = this.$route.params?.id
    await this.fetch()
  },
  methods: {
    //************************ handel Save************************
    async submitForm() {
      const [variantEditorValid, formValid] = await Promise.all([
        this.$refs.variantEditor.validate(),
        this.validate()
      ])

      if (!variantEditorValid || !formValid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }

      this.current.option_template = this.$refs.variantEditor.getItemsAsObject()
      const isNew = this.isNew
      const resource = this.$t('common.resource.product_template.singular')
      const { error, data } = await this.save()

      if (isNew) {
        this.$ampli.eventWithBaseProps('productVariantTemplateCreated')
      }

      if (data?.id) {
        const successMessage = isNew
          ? this.$t('common.success.action.create.single', { resource })
          : this.$t('common.success.action.update.single', { resource })
        this.$message({
          type: 'success',
          message: successMessage
        })

        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'products-product-templates-edit',
            params: { id: data.id }
          })
        } else {
          this.$router.push({ name: 'products-product-templates-all' })
        }
      }
      if (error) {
        this.$logException(error, {
          message: this.$t('common.error.action.update.single', { resource })
        })
      }
    },

    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },

    //************************ handel Delete ************************
    async delete() {
      const confirm = await this.$askToDelete(this.current.name || this.id)
      if (confirm) this.deleteItem()
    },
    async deleteItem() {
      const { error } = await this.deleteModel()
      if (error) {
        this.$logException(error, {
          message: this.$t('common.error.action.delete.single', {
            resource: this.$t('common.resource.product_template.singular')
          })
        })
        return
      }

      this.$message({
        type: 'success',
        message: this.$t('common.success.action.delete.single', {
          resource: this.$t('common.resource.product_template.singular')
        })
      })
      this.$router.push({ name: 'products-product-templates-all' })
    }
  }
}
</script>
