/*
 * transform data from array to object
 * for example:
 * [{ key:'x', values:['a', 'b'] }] => { x:['a', 'b'] }
 */
export const getItemsAsObject = (items) => {
  return items.reduce((optionTemplates, { key, values }) => {
    if (key) {
      optionTemplates[key] = values
    }
    return optionTemplates
  }, {})
}

/*
 * transform data from object to array
 * for example:
 * { x:['a', 'b'] } => [{ key:'x', values:['a', 'b'] }]
 */
export const getItemsAsArray = (items) => {
  return Object.entries(items).map(([key = null, values]) => ({
    key,
    values
  }))
}
